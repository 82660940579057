import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <p><strong parentName="p">{`*`}{`*`}{`True Grit 6 is Saturday, September 15th.  We will have no classes
or open gym on Saturday but if you aren’t competing, judging or
volunteering come out and cheer on the competitors!  The first heat will
start at 9:00am.  **`}</strong>{`For more info go to:
`}<a parentName="p" {...{
        "href": "http://truegritcomp.wordpress.com/"
      }}>{`truegritcomp.wordpress.com`}</a>{`***`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 5:30pm class will be cancelled today in order for us to set up
for True Grit.  Open gym will also be cancelled at this time.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts on 9/23.  For more info email Eric
at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      